import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000001;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  & strong {
    color: #e74c3c;
  }

  .modal-section {
    width: fit-content;
    background-color: white;
    overflow: auto;
    border: none;
    display: flex;
    flex-direction: column;
    padding: 30px;
    border-radius: 10px;

    & > i {
      font-size: 2rem;
      color: black;
      cursor: pointer;
      :hover {
        color: #3bb77e;
      }
    }
    @media (max-width: 767px) {
      padding: 10px;
    }
    @media (max-width: 575px) {
      padding: 10px;
      width: 90vw;
    }
  }

  .modal-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border: none;
    margin: 0 auto;
    & h5 {
      font-size: 18px;
      margin: 15px 0px;
      & pre {
        overflow: visible !important;
        text-align: center;
      }
      @media (max-width: 575px) {
        font-size: 1rem;
      }
    }
    & img {
      width: 75%;
    }

    & a {
      margin-top: 20px;
      width: 200px;
    }

    @media (max-width: 991px) {
      & a {
        width: 150px;
      }
    }
    @media (max-width: 767px) {
      & a {
        width: 130px;
      }
    }
  }
`;

const InquiryCompleteModal = ({
  onClickInquiryComplete,
  confirmText,
  confirmImg,
}) => {
  globalThis.onkeydown = (e) => {
    if (e.code === "Escape") return onClickInquiryComplete();
  };

  return (
    <>
      <ModalWrapper>
        <div className="modal-section">
          <div className="modal-content">
            <img
              src={
                confirmImg ||
                "/assets/imgs/page/common/inquiry_complete_icon.png"
              }
            />
            <h5>{confirmText || "소중한 정보 감사합니다!"}</h5>
            <a className="btn" onClick={onClickInquiryComplete}>
              완료
            </a>
          </div>
        </div>
      </ModalWrapper>
    </>
  );
};
export default InquiryCompleteModal;
