import Link from "next/link";
import { connect } from "react-redux";
import { insertBaskets } from "../../../api/baskets";
import { priceComma } from "../../../common/splicePrice";
import { addToCart, increaseCnt } from "../../../redux/action/cart";
import { openQuickView } from "../../../redux/action/quickViewAction";
import { showToastAlert } from "../../../redux/action/alert";
import { useEffect, useState } from "react";
import { addToCartAlert } from "../../../common/alert";
import {
  insertBasketsAction,
  fetchBasketsAction,
} from "../../../redux/action/baskets";
import { getCookie } from "../../../common/auth";
import * as Single from "./SingleProduct-styles";
import { ViewCountProduct } from "../../../api/products";
import { getDate } from "../../../common/util/getDate";

const SingleProduct = ({
  product,
  basketsItems,
  cartItems,
  basketsCount,
  addToCart,
  addToWishlist,
  openQuickView,
  increaseCnt,
  showToastAlert,
  insertBasketsAction,
  fetchBasketsAction,
  dummyList,
  addCart,
  inCart,
}) => {
  const inBasket = inCart
    ? inCart(product)
    : basketsItems?.find(
        (basketsItem) => basketsItem.pcode === product.pcode
      ) || cartItems?.find((cartItem) => cartItem.pcode === product.pcode);

  const handleCart = async (product) => {
    if (product) {
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          items: [
            {
              item_name: product.pname, // 상품 이름
              item_id: product.pcode, // 상품 ID
              price: product.price, // 상품 개별 가격
              item_brand: product.supplier, // 브랜드이름
              item_category:
                product.category_code.length > 2
                  ? product.category_code
                  : dummyList?.category[product.category_code], // 대 카테고리
            },
          ],
        },
      });
      // 채널톡 장비구니 이벤트
      window.ChannelIO("track", "AddToCart", {
        pcode: product.pcode,
      });
    }

    if (!getCookie("token")) {
      return addToCart(product);
    } else {
      return insertBasketsAction(product, 1);
    }
  };

  const moveToDetail = async () => {
    const body = {
      modes: "ViewCount",
      pcode: product.pcode,
    };
    const res = await ViewCountProduct(body);
  };

  return (
    <>
      <Single.Wrapper>
        <Single.ProductImgWrapper>
          <Link href="/products/[slug]" as={`/products/${product.pcode}`}>
            <Single.ProductImg onClick={moveToDetail}>
              <img src={product.main_image} alt="" />
            </Single.ProductImg>
          </Link>
          {product.sfnz_view === "Y" && (
            <Single.SfnzBadge>
              <img
                src={
                  "https://file.caretbio.com/caretbio/75378c147862e82d7a9ede3651163fdd.png"
                }
                alt="SFNZ_badge"
              />
            </Single.SfnzBadge>
          )}
        </Single.ProductImgWrapper>
        <Single.ProductContentWrapper>
          <Link href="/products/[slug]" as={`/products/${product.pcode}`}>
            <Single.ProductName onClick={moveToDetail}>
              {product.pname}
            </Single.ProductName>
          </Link>
          <Single.ProductBottomWrapper>
            <Single.PriceWrapper>
              <Single.PriceBox>
                {product.salePer > 0 && (
                  <div>
                    <span>{product.salePer}%</span>
                    <span>
                      {product.price && ` ${priceComma(product.price)}원`}
                    </span>
                  </div>
                )}
                <h5>{priceComma(product.discount_price)}원</h5>
              </Single.PriceBox>
            </Single.PriceWrapper>
            {product.sold_out === "Y" ? (
              <Single.CartIconBox>
                <span>품절</span>
              </Single.CartIconBox>
            ) : (
              <Single.CartIconBox>
                {!inBasket ? (
                  <a
                    onClick={(e) =>
                      addCart ? addCart(product) : handleCart(product)
                    }
                  >
                    <i className="fi-rs-shopping-cart-add" />
                  </a>
                ) : (
                  <a
                    onClick={(e) =>
                      addCart ? addCart(product) : handleCart(product)
                    }
                  >
                    <i className="fi-rs-shopping-cart-check" />
                  </a>
                )}
              </Single.CartIconBox>
            )}
          </Single.ProductBottomWrapper>
          <Single.StickerWrapper>
            <Single.ViewBox>
              <div>
                {priceComma(product.point)}
                <Single.Sticker>P</Single.Sticker> 적립
              </div>
              <div>
                <img src="/assets/imgs/theme/icons/icon-view.png" />
                {product.hit}
              </div>
            </Single.ViewBox>
          </Single.StickerWrapper>
        </Single.ProductContentWrapper>
      </Single.Wrapper>
    </>
  );
};

const mapStateToProps = (state) => ({
  cartItems: state.cart,
  basketsItems: state.baskets.items,
  basketsCount: state.baskets.count,
});

const mapDispatchToProps = {
  addToCart,
  openQuickView,
  increaseCnt,
  showToastAlert,
  insertBasketsAction,
  fetchBasketsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct);
