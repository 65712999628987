import { useEffect } from "react";
import { useState } from "react";
import { subscribeInsetSet } from "../../api/subscribe";
import { failAlert, successAlert } from "../../common/alert";
import InquiryCompleteModal from "../elements/inquiryCompleteModal";
import * as News from "./newsletter.styles";
import modalOverflowHook from "../../common/modalOverflowHook";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [openInquiryComplete, setOpenInquiryComplete] = useState(false);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onClickInquiryComplete = () => {
    setOpenInquiryComplete((prev) => !prev);
  };

  const subscribe = async () => {
    if (email == "") {
      failAlert("이메일을 입력해주세요.");
      return;
    }
    const body = {
      modes: "InsertSet",
      email: email,
    };
    const res = await subscribeInsetSet(body);
    if (res.Code === "0000") {
      onClickInquiryComplete();
      successAlert(res?.Data?.Msg || "구독 신청이 완료되었습니다.");
      setEmail("");
    } else {
      failAlert(res.Data?.Msg || "구독 신청에 실패했습니다.");
    }
  };

  const modalScrollHook = modalOverflowHook(openInquiryComplete);

  return (
    <>
      <div className="container">
        <News.Section>
          <h6>
            최신 환경정책과 포장재 트렌드, <br />
            칼렛스토어의 신제품 출시 소식을 가장 먼저 받아보세요!
          </h6>
          <img
            src="/assets/imgs/page/subscribe/icon.png"
            alt="newsletter_btn"
            className="icon"
          />
          <News.SubscibeWrapper>
            <input
              onChange={onChangeEmail}
              type="text"
              placeholder="이메일을 입력하세요"
              value={email || ""}
            />
            <div onClick={subscribe}>
              <img src="/assets/imgs/page/subscribe/subscribe_btn.png" />
            </div>
          </News.SubscibeWrapper>
          <News.NewsletterWrapper>
            <a href="https://caretstore.stibee.com/" target={"_blank"}>
              <img
                src="/assets/imgs/page/subscribe/newsletter_btn.png"
                alt="newsletter_btn"
                className="newsletter_btn"
              />
              <span className="before_newsletter_bg">
                지난 뉴스레터 <br />
                보러가기
              </span>
              <img
                src="/assets/imgs/page/subscribe/before_newsletter_icon.png"
                alt="before_newsletter_icon"
                className="before_newsletter_icon"
              />
            </a>
          </News.NewsletterWrapper>
        </News.Section>
      </div>
      {openInquiryComplete && (
        <InquiryCompleteModal onClickInquiryComplete={onClickInquiryComplete} />
      )}
    </>
  );
};
export default Newsletter;
