import styled from "styled-components";

export const HomeTopBannerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 25px;
  height: 100%;
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .swiper-button-prev {
    color: #3bb77e;
    left: 0px;
    ::after {
      font-size: 30px;
    }
  }
  .swiper-button-next {
    color: #3bb77e;
    right: 0px;
    ::after {
      font-size: 30px;
    }
  }
  .swiper-pagination-fraction {
    background-color: #000;
    width: fit-content;
    padding: 0px 10px;
    border-radius: 20px;
    left: auto;
    right: 15px;
    color: #fff;
    font-size: calc(10px + (14 - 10) * ((100vw - 320px) / (1920 - 320)));
  }
`;

export const Section = styled.section`
  overflow: hidden;
  width: 100%;
  .pc_swiper {
    display: block;
  }
  .m_swiper {
    display: none;
    overflow: visible;
    margin: 0 auto;
    position: relative;
  }

  @media screen and (max-width: 767px) {
    padding: 0px;
    .pc_swiper {
      display: none;
    }
    .m_swiper {
      display: block;
    }
  }
`;

export const HomeTopNoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fef8e2;
  border: 1px solid #fef8e2;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
  height: 100%;

  & ul {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
  }
  & li {
    & a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #253d4e;
    }
    & span {
      white-space: nowrap;
    }
    .title {
      text-overflow: ellipsis;
      overflow: hidden;
      -webkit-line-clamp: 1;
    }
    :hover {
      & a,
      span {
        color: #3bb77e;
      }
    }
  }
  @media screen and (max-width: 1199px) {
    max-height: 161.34px;
    & li {
      overflow: hidden;
    }
  }
  @media screen and (max-width: 991px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;
