import styled from "styled-components";

export const PatentWrapper = styled.div`
  position: relative;
`;

export const FooterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & h6 {
    font-size: 1.8rem !important;
  }
  margin-bottom: 30px;
`;

export const SlideArrows = styled.div`
  display: flex;
  .slider-btn {
    cursor: pointer;
    background: #f6f6f6;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    border-radius: 50%;
    transition: all 0.2s ease-out;
    text-align: center;
    font-size: 24px;
    color: #7e7e7e;
    :hover {
      background-color: #3bb77e;
      color: #fff;
    }
    :first-of-type {
      margin-right: 10px;
    }
  }
`;

export const SliderBox = styled.div`
  & span {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    word-break: keep-all;
  }
  & img {
    margin-bottom: 10px;
    -webkit-box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
  }
`;
