import { useEffect, useRef } from "react";
import styled from "styled-components";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

SwiperCore.use([Navigation, Autoplay, Pagination]);

const MobileBannerWrapper = styled.div`
  .P {
    display: block;
  }
  .M {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .P {
      display: none;
    }
    .M {
      display: block;
    }
    .swiper-pagination-bullet {
      width: 10px !important;
      height: 10px !important;
    }
  }
`;

const HomeMobileBanner = ({ banners }) => {
  const mobileSwiperRef = useRef();
  useEffect(() => {
    if (
      banners &&
      banners?.mobile_btm?.length > 1 &&
      mobileSwiperRef.autoplay
    ) {
      mobileSwiperRef.autoplay.start();
      mobileSwiperRef.autoplay.run();
      mobileSwiperRef.update();
    }
  });

  return (
    <>
      {banners && banners?.main_event_stripe?.length > 0 && (
        <MobileBannerWrapper>
          <Swiper
            className="mobileBanner"
            autoplay={false}
            pagination={false}
            loop={false}
          >
            {banners.main_event_stripe.map((el, i) => (
              <SwiperSlide key={i}>
                <a
                  href={el.link_url}
                  target={el.new_win === "Y" ? "_blank" : "_self"}
                >
                  <img src={el.P_FileUrl} alt={el.img_alt} className="P" />
                  <img src={el.M_FileUrl} alt={el.img_alt} className="M" />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </MobileBannerWrapper>
      )}
    </>
  );
};
export default HomeMobileBanner;
