import http from "./constants/http";
import resources from "./constants/resources";

// 메인페이지 카테고리텝 상품목록
export const fetchProductsMain = async (body) => {
  const res = await http(resources.PRODUCTS).post(body);
  const resData = {
    code: res?.data?.Code,
    data: res?.data.Data?.Rows,
    count: res?.data.Data?.Count,
  };
  return resData;
};

// 상품 디테일페이지
export const fetchProductView = async (body) => {
  const res = await http(resources.PRODUCTS).post(body);
  const resData = {
    code: res?.data?.Code,
    data: res?.data?.Data?.Row,
  };
  return resData;
};

// 카테고리 fetch
export const listSetCategory = async (body) => {
  const res = await http(resources.CATEGORY).post(body);
  const resData = {
    code: res?.data?.Code,
    data: res?.data?.Data.Category,
  };
  return resData;
};

// 카테고리 별 상품 리스트
export const getListCategoryProducts = async (body) => {
  const res = await http(resources.PRODUCTS).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

// 해시태그 리스트
export const hashSetProducts = async (body) => {
  const res = await http(resources.PRODUCTS).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

// 상품 조회수 증가
export const ViewCountProduct = async (body) => {
  const res = await http(resources.PRODUCTS).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

// 상품 검색
export const searchProducts = async (body) => {
  const res = await http(resources.PRODUCTS).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

// 제작문의
export const inquirySet = async (body) => {
  const res = await http(resources.PRODUCTS).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};

// products
export const ProductsApi = async (body) => {
  const res = await http(resources.PRODUCTS).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};
