import { useEffect, useState } from "react";
import styled from "styled-components";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination]);

const SwiperWrapper = styled.div`
  height: 400px;
  width: 100%;
  overflow: hidden;
  .slider-arrow {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  :hover {
    .slider-arrow {
      opacity: 1;
      visibility: visible;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  .M {
    display: none;
    width: 100%;
  }

  .pagination {
    position: absolute;
    background-color: transparent;
    width: 100%;
    bottom: 0;
    z-index: 1;
    color: #fff;
  }

  .pagination-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    height: 37px;
    overflow: hidden;
  }

  .swiper-pagination-bullet {
    cursor: pointer;
    width: 100% !important;
    height: 37px !important;
    background-color: #fff;
    opacity: 1;
    border-radius: 0;
    color: #1b1b1b;
    padding: 0px 10px;
    text-align: center;
    border-bottom: 3px solid #fff;
    line-height: 37px;
    border-radius: 0;
    font-size: 14px;
    margin: 0 !important;
    opacity: 1;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    :not(:last-child)::before {
      content: "";
      border-right: 1px solid #ccc;
      height: 20px;
      background: #ccc;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      right: 0;
    }
  }
  .swiper-pagination-bullet-active {
    background-color: #fff !important;
    border-color: #3bb77e;
  }

  @media (max-width: 1399px) {
    height: auto;
  }
  @media (max-width: 1199px) {
    .pagination-wrapper {
      height: 30px;
    }
    .swiper-pagination-bullet {
      height: 30px !important;
      font-size: 12px !important;
      line-height: 30px;
      :not(:last-child)::before {
        height: 15px;
        margin-top: -5px;
      }
    }
  }

  @media (max-width: 991px) {
    .pagination {
      display: none;
    }
  }
  @media (max-width: 767px) {
    .M {
      display: flex;
    }
    .slider-arrow {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
      :hover {
        display: none !important;
        opacity: 0 !important;
        visibility: hidden !important;
      }
      & span {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        & i {
          margin-right: 0px !important;
        }
      }
    }
  }
  @media screen and (max-width: 575px) {
    .swiper-pagination-bullet {
      width: 10px !important;
      height: 10px !important;
    }
  }
`;

const SilideEl = styled.div`
  width: 100%;
  height: 400px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;

  & a {
    position: absolute;
    overflow: hidden;
    height: 100%;
  }

  .P {
    max-width: none;
    width: auto;
    height: 100%;
  }

  @media (max-width: 1199px) {
    height: 300px;
  }

  @media (max-width: 991px) {
    height: 260px;
  }

  @media (max-width: 767px) {
    display: none;
  }
`;

const Intro1 = ({ banners }) => {
  const [paginationEl, setPaginationEl] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [pagination, setPagination] = useState([]);
  const paginationLength = 6;
  const checkIdx = (list) => {
    const arr = [...banners.main_top];
    // 배열의 길이가 슬라이더 길이와 같거나 작을때 기본형
    if (list.length <= length) {
      const temp = arr.splice(0, paginationLength).map((el) => el);
      setPagination(temp);
      return;
    }
    // 현재 인덱스가 총 길이보다 클때
    if (activeIndex > paginationLength - 1) {
      // 길이보다 크면서 배열의 길이보다 작거나 같을때
      if (activeIndex <= list.length) {
        const temp = arr
          .splice(activeIndex - paginationLength + 1, paginationLength)
          .map((el) => el);
        setPagination(temp);
        return;
      } else {
        const temp = arr
          .splice(list.length - paginationLength, paginationLength)
          .map((el) => el);
        setPagination(temp);
        return;
      }
    }
    // 그냥 기본형
    const temp = arr.splice(0, paginationLength).map((el) => el);
    setPagination(temp);
  };

  useEffect(() => {
    if (!banners?.main_top) return;
    checkIdx(banners?.main_top);
  }, [activeIndex, banners]);

  return (
    <>
      {banners && banners.main_top?.length > 0 && (
        <SwiperWrapper>
          <Swiper
            slidesPerView={1}
            autoplay={true}
            loop={true}
            pagination={{
              clickable: true,
              el: paginationEl,
              type: "custom",
            }}
            navigation={{
              prevEl: ".custom_prev_i1",
              nextEl: ".custom_next_i1",
            }}
            className="custom-class"
            initialSlide={0}
            onActiveIndexChange={(swiper) => {
              if (Number(swiper.activeIndex) <= 0) {
                setActiveIndex(banners.main_top.length - 1);
                return;
              }
              if (Number(swiper.activeIndex) > banners.main_top.length) {
                setActiveIndex(0);
                return;
              }
              setActiveIndex(swiper.activeIndex - 1);
            }}
          >
            {banners.main_top.map((el, i) => (
              <SwiperSlide key={i}>
                <>
                  <SilideEl>
                    <a
                      href={el.link_url}
                      target={el.new_win === "Y" ? "_blank" : "_self"}
                    >
                      <img src={el.P_FileUrl} alt={el.img_alt} className="P" />
                    </a>
                  </SilideEl>
                  <a
                    href={el.link_url}
                    target={el.new_win === "Y" ? "_blank" : "_self"}
                  >
                    <img src={el.M_FileUrl} alt={el.img_alt} className="M" />
                  </a>
                </>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="slider-arrow">
            <span className="slider-btn slider-prev slick-arrow custom_prev_i1">
              <i className="fi-rs-angle-left"></i>
            </span>
            <span className="slider-btn slider-next slick-arrow custom_next_i1">
              <i className="fi-rs-angle-right"></i>
            </span>
          </div>
          <div className="swiper-pagination pagination" ref={setPaginationEl}>
            <div className="container">
              <div className="pagination-wrapper">
                {pagination.map((el, i) => (
                  <div
                    className={`swiper-pagination-bullet ${
                      activeIndex ===
                      banners.main_top.findIndex(
                        (banner) => banner.img_alt === el.img_alt
                      )
                        ? "swiper-pagination-bullet-active"
                        : ""
                    }`}
                    key={i}
                  >
                    <span>{el.img_alt}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </SwiperWrapper>
      )}
    </>
  );
};

export default Intro1;
