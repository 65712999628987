import http from "./constants/http";
import resources from "./constants/resources";

// 팝업 api
export const PopupsListSet = async () => {
  const body = {
    modes: "ListSet",
  };
  const res = await http(resources.POPUPS).post(body);
  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};
