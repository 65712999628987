import styled, { keyframes } from "styled-components";

const ScaleUpX = keyframes`
  from{
    opacity: 0;
    width: 0;
    color: transparent;
  }
  to{
    opacity: 1;
    color: #fff;
    width: 200px;
  }
`;

export const Section = styled.section`
  display: flex;
  position: relative;
  width: 100%;
  min-height: 280px;
  background-color: #d8f1e5;
  overflow-x: hidden;
  & h6 {
    word-break: keep-all;
    position: absolute;
    top: 10%;
    left: 5%;
    font-size: 20px;
    line-height: 1.5;
  }
  .icon {
    position: absolute;
    right: 30%;
    top: 20%;
    width: 240px;
    height: 155px;
  }
  @media (max-width: 1199px) {
    .icon {
      right: 10%;
    }
  }
  @media (max-width: 991px) {
    & h6 {
      top: 20%;
    }
    .icon {
      display: none;
    }
  }
  @media (max-width: 767px) {
    & h6 {
      width: 100%;
      font-size: 18px;
      left: 10%;
    }
  }
  @media (max-width: 575px) {
    & h6 {
      width: 80%;
      font-size: 18px;
      left: 5%;
    }
  }
`;

export const SubscibeWrapper = styled.div`
  width: ${(props) => (props.esg ? "60%" : "40%")};
  display: flex;
  align-content: center;
  justify-content: space-between;
  position: absolute;
  background-color: #fff;
  height: 50px;
  bottom: 70px;
  left: 50px;
  border-radius: 20px;

  & input {
    width: 60%;
    height: 100%;
    border: none;
    background-color: transparent;
  }

  & div {
    position: absolute;
    right: -10%;
    bottom: 7%;
    cursor: pointer;
    transition: all 0.5s;
    height: 100%;
    :hover {
      transition: all 0.5s;
      transform: translateX(-20px);
    }
  }

  @media (max-width: 1199px) {
    width: 50%;
    & div {
      & img {
        height: 120%;
      }
    }
  }

  @media (max-width: 991px) {
    width: 60%;
    & div {
      bottom: 0%;
      & img {
        height: 110%;
      }
    }
  }

  @media (max-width: 767px) {
    width: 70%;
    & input {
    }
    & div {
      cursor: pointer;
      transition: all 0.5s;
      & img {
      }
      :hover {
        transition: all 0.5s;
      }
    }
  }
  @media (max-width: 575px) {
    width: 80%;
    left: 5%;
  }
`;

export const NewsletterWrapper = styled.div`
  width: 304px;
  min-height: 280px;
  background: url("/assets/imgs/page/subscribe/newsletter.jpg") no-repeat center;
  position: absolute;
  display: flex;
  justify-content: center;
  right: 0;

  .before_newsletter_icon {
    display: none;
    z-index: 19;
  }

  & a {
    position: absolute;
    bottom: 15px;
    cursor: pointer;
    transition: all 0.3s;
    display: flex;
    justify-content: end;
    :hover {
      transition: all 0.3s;
      transform: translateY(-10px);
    }

    .before_newsletter_bg {
      display: none;
    }
  }

  @media (max-width: 1199px) {
    display: flex;
    background-image: none;
    width: 100px;

    :hover {
      .before_newsletter_bg {
        width: 200px;
        height: 55px;
        text-align: center;
        display: flex;
        align-items: center;
        position: absolute;
        border-radius: 30px;
        bottom: 10px;
        right: 10px;
        padding-left: 20px;
        background-color: #3bb77e;
        animation: ${ScaleUpX} 1s ease alternate;
        color: #fff;
        font-size: 18px;
        white-space: nowrap;
      }
    }

    .newsletter_btn {
      display: none;
    }
    .before_newsletter_icon {
      display: flex;
    }
    .before_newsletter_bg {
      display: flex;
    }
  }
  @media (max-width: 991px) {
    display: flex;
    background-image: none;
    width: 100px;

    .newsletter_btn {
      display: none;
    }

    & a {
      bottom: 0;
      right: 0;
      :hover {
        transform: translateY(-5px);
      }
    }
    .before_newsletter_icon {
      display: flex;
    }
    .before_newsletter_bg {
      display: flex;
    }
  }
  @media (max-width: 767px) {
  }
`;
