import React, { useEffect, useState } from "react";
import Cat1Tab from "../elements/FeaturedTab";
import { fetchProductsMain } from "../../api/products";

function CategoryTab() {
  const [active, setActive] = useState("");
  const [catProducts, setCatProducts] = useState({});

  const fetchProducts = async () => {
    const body = { modes: "Main" };
    const data = await fetchProductsMain(body);
    setCatProducts(data?.data);
    setActive(Object.keys(data?.data)[0]);
  };

  const catType = async (type) => {
    setActive(type);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    const keys = Object.keys(catProducts);
    if (keys.length === 0) return;
    const updateKeyAndValue = () => {
      setActive(
        (prev) => keys[(keys.findIndex((v) => v === prev) + 1) % keys.length]
      );
    };
    const intervalId = setInterval(updateKeyAndValue, 3000);
    return () => clearInterval(intervalId);
  }, [catProducts]);

  return (
    <>
      <div className="section-title style-2 wow animate__animated animate__fadeIn">
        <h4>추천상품</h4>
        <ul className="nav nav-tabs links" id="myTab" role="tablist">
          {catProducts &&
            Object.keys(catProducts).length > 0 &&
            Object.keys(catProducts).map((cat, i) => (
              <li className="nav-item" role="presentation" key={i}>
                <a
                  className={active === cat ? "nav-link active" : "nav-link"}
                  onClick={() => catType(cat)}
                >
                  {cat}
                </a>
              </li>
            ))}
        </ul>
      </div>

      <div className="tab-content wow fadeIn animated">
        <div className={"tab-pane fade show active"}>
          <div className="product-grid-4 row">
            <Cat1Tab products={catProducts[active]} />
          </div>
        </div>
      </div>
    </>
  );
}
export default CategoryTab;
