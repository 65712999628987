import http from "./constants/http";
import resources from "./constants/resources";

export const fetchBoards = async (body) => {
  const res = await http(resources.BOARDS).post(body);

  const resData = {
    code: res?.data?.Code,
    data: res?.data?.Data?.rows,
  };

  return resData;
};

export const ViewSetBoard = async (body) => {
  const res = await http(resources.BOARDS).post(body);

  if (res.status === 200) {
    return res.data;
  } else {
    return res;
  }
};
