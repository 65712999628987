import styled from "styled-components";
import SingleProduct from "../ecommerce/singleProduct/SingleProduct";

const SingleProductTap = styled.div`
  @media (max-width: 575px) {
    width: 50% !important;
  }
  @media (max-width: 349px) {
    width: 100% !important;
  }
`;

const FeaturedTab = ({ products }) => {
  return (
    <>
      {products &&
        products?.map((product, i) => (
          <SingleProductTap
            className="col-12 col-lg-1-5 col-md-4 col-sm-6"
            key={i}
          >
            <SingleProduct product={product} />
          </SingleProductTap>
        ))}
    </>
  );
};

export default FeaturedTab;
