import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 260px;
  left: 10vw;
  width: fit-content;
  display: flex;

  @media screen and (max-width: 1199px) {
    left: 5vw;
  }
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @media screen and (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
    display: flex;
  }
  @media screen and (max-width: 575px) {
    display: flex;
  }
`;

export const PopupBox = styled.div`
  z-index: 100000009;
  width: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  border: ${(props) => (props.content ? "1px solid #ececec" : "0px")};
  overflow: hidden;
  @media screen and (max-width: 767px) {
    position: absolute;
  }
  @media screen and (max-width: 575px) {
    width: 300px;
    position: absolute;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BoxBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .m_image {
    display: none;
  }

  @media screen and (max-width: 575px) {
    .pc_image {
      display: none;
    }
    .m_image {
      display: flex;
    }
  }
`;

export const BoxFooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ececec;
  background-color: white;
`;

export const FooterBtn = styled.span`
  display: flex;
  justify-content: center;
  width: 50%;
  padding: 10px 0;
  border-right: 1px solid #ececec;
  cursor: pointer;
  :hover {
    background-color: #ececec;
  }
  :last-of-type {
    border: none;
  }
`;
