import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { PopupsListSet } from "../../api/popups";
import { getCookie, setCookie } from "../../common/auth";
import { getDate, getNextDate, getTimeStamp } from "../../common/util/getDate";
import * as P from "./popup.styles";

const Popup = () => {
  const router = useRouter();
  const [popupList, setPopupList] = useState([]);

  const fetchPopups = async () => {
    const res = await PopupsListSet();
    if (res?.Code === "0000") {
      const filtered = res?.Data?.rows.filter(
        (el) => !getCookie(`IgP${el.id}`)
      );
      setPopupList(filtered);
    } else {
      console.error(res?.Data?.Msg);
    }
  };

  const onClickClose = (id) => {
    popupList.splice(
      popupList.findIndex((el) => el.id === id),
      1,
      ""
    );
    setPopupList([...popupList]);
  };

  const onClickTodayClose = (id) => {
    const date = getNextDate();
    setCookie(`IgP${id}`, date, {
      expires: date,
    });
    onClickClose(id);
  };

  const openLink = (url, option) => {
    if (url) {
      option === "Y" ? window.open(url) : router.push(url);
    }
  };

  useEffect(() => {
    fetchPopups();
  }, []);

  return (
    <>
      {popupList && popupList.length > 0 && (
        <P.Container>
          <P.Body>
            {popupList.map((content, i) => (
              <P.PopupBox key={i} content={content}>
                {content && (
                  <P.BoxBody
                    onClick={() => openLink(content.link_url, content.new_win)}
                  >
                    <img
                      src={content.P_FileUrl}
                      className="pc_image"
                      alt={content.img_alt}
                    />
                    <img
                      src={content.M_FileUrl}
                      className="m_image"
                      alt={content.img_alt}
                    />
                  </P.BoxBody>
                )}
                {content && (
                  <P.BoxFooter>
                    <P.FooterBtn onClick={() => onClickTodayClose(content.id)}>
                      오늘 하루 안보기
                    </P.FooterBtn>
                    <P.FooterBtn onClick={() => onClickClose(content.id)}>
                      닫기
                    </P.FooterBtn>
                  </P.BoxFooter>
                )}
              </P.PopupBox>
            ))}
          </P.Body>
        </P.Container>
      )}
    </>
  );
};
export default Popup;
