import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination]);
import * as T from "./HomeTopBanner.styles";
import Image from "next/image";
import { useEffect, useState } from "react";
import { fetchBoards } from "../../../api/boards";
import { Spinner } from "react-bootstrap";
import { shuffleArray } from "../../../common/helper";

const HomeTopBanner = ({ banners }) => {
  const [noticeList, setNoticeList] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [bannersLoading, setBannersLoading] = useState(true);
  const [shuffledBanners, setShuffledBanners] = useState([]);
  const fetchNoticeList = async () => {
    setFetchLoading(true);
    const body = {
      modes: "List",
      boardName: "notice",
    };
    const res = await fetchBoards(body);
    if (res.code === "0000" && res?.data?.data?.length > 0) {
      setNoticeList([...res.data.data.slice(0, 5)]);
    }
    setFetchLoading(false);
  };

  useEffect(() => {
    fetchNoticeList();
  }, []);

  useEffect(() => {
    if (
      banners &&
      banners.main_top_event?.length > 0 &&
      banners.main_top_center_event &&
      shuffledBanners.length === 0
    ) {
      setShuffledBanners(
        shuffleArray([
          ...banners.main_top_event,
          ...banners.main_top_center_event,
        ])
      );
      setBannersLoading(false);
    }
  }, [banners]);

  return (
    <>
      <T.HomeTopBannerWrapper className="container">
        <T.Section>
          {/* pc swiper */}
          {banners && banners.main_top_event?.length > 0 && (
            <Swiper
              freeMode={true}
              slidesPerView={1}
              observer={true}
              observeParents={true}
              spaceBetween={25}
              watchSlidesProgress={true}
              className="pc_swiper"
              navigation={true}
              pagination={{ type: "fraction" }}
            >
              {banners.main_top_event.map((banner, i) => (
                <SwiperSlide key={i}>
                  <a
                    href={banner.link_url}
                    target={banner.new_win === "Y" ? "_blank" : "_self"}
                  >
                    <figure>
                      <Image
                        src={banner.P_FileUrl}
                        width={362}
                        height={198}
                        sizes="100vw"
                        priority
                        alt={banner.img_alt}
                      />
                    </figure>
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
          {/* mobile Swiper */}
          {bannersLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          ) : (
            shuffledBanners?.length > 0 && (
              <Swiper
                observer={true}
                observeParents={true}
                spaceBetween={10}
                className="m_swiper"
                pagination={{ type: "fraction" }}
                loop={true}
              >
                {shuffledBanners.map((banner, i) => (
                  <SwiperSlide key={i}>
                    <a
                      href={banner.link_url}
                      target={banner.new_win === "Y" ? "_blank" : "_self"}
                    >
                      <figure>
                        <Image
                          src={banner.M_FileUrl}
                          width={326}
                          height={105}
                          sizes="100vw"
                          priority
                          alt={banner.img_alt}
                        />
                      </figure>
                    </a>
                  </SwiperSlide>
                ))}
              </Swiper>
            )
          )}
        </T.Section>
        <T.Section>
          {/* pc swiper */}
          {banners && banners.main_top_center_event?.length > 0 && (
            <Swiper
              freeMode={true}
              slidesPerView={1}
              observer={true}
              observeParents={true}
              spaceBetween={25}
              watchSlidesProgress={true}
              className="pc_swiper"
              navigation={true}
              pagination={{ type: "fraction" }}
            >
              {banners.main_top_center_event.map((banner, i) => (
                <SwiperSlide key={i}>
                  <a
                    href={banner.link_url}
                    target={banner.new_win === "Y" ? "_blank" : "_self"}
                  >
                    <figure>
                      <Image
                        src={banner.P_FileUrl}
                        width={362}
                        height={198}
                        sizes="100vw"
                        priority
                        alt={banner.img_alt}
                      />
                    </figure>
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </T.Section>
        <T.HomeTopNoticeWrapper>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h6>Notice</h6>
            <a href="/csboard/notice/">more</a>
          </div>
          {fetchLoading ? (
            <div className="h-100 d-flex align-items-center justify-content-center">
              <Spinner />
            </div>
          ) : (
            <ul>
              {noticeList?.length > 0 &&
                noticeList.map((v, i) => (
                  <li key={i}>
                    <a href={`/csboard/notice/${v.id}/`}>
                      <span className="title">{v.title}</span>
                      <span className="date">{v.wdate}</span>
                    </a>
                  </li>
                ))}
            </ul>
          )}
        </T.HomeTopNoticeWrapper>
      </T.HomeTopBannerWrapper>
    </>
  );
};
export default HomeTopBanner;
