import SwiperCore, { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import styled from "styled-components";
import { Html } from "next/document";

const Instagram = styled.section`
  width: 100%;
  max-width: 100%;
  padding: 50px 0px;
  background-color: rgb(246, 246, 246);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: none !important;
`;

const SlideArrows = styled.div`
  display: flex;
  .slider-btn {
    cursor: pointer;
    background: #fff;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    border-radius: 50%;
    transition: all 0.2s ease-out;
    text-align: center;
    font-size: 24px;
    color: #7e7e7e;
    :hover {
      background-color: #3bb77e;
      color: #fff;
    }
    :first-of-type {
      margin-right: 10px;
    }
  }
`;

const FooterTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & h6 {
    font-size: 1.8rem !important;
  }
  margin-bottom: 30px;
`;

const SNSboard = styled.a`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 300px;
  transition: none !important;
  :hover {
    & div {
      height: 130px;
    }
  }

  & img {
    width: 100%;
    max-width: 100vw;
    margin-bottom: 10px;
  }

  & div {
    color: #696969;
    width: 100%;
    height: 100px;
    padding: 10px 15px;
    font-size: 14px;
    word-break: break-all;
    transition: height 0.3s ease;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
  }
`;

SwiperCore.use([Navigation, Autoplay]);

const Subscibe = ({ banners }) => {
  return (
    <Instagram style={{ position: "relative" }}>
      <FooterTitle className="container">
        <h4>인기컨텐츠</h4>
        <SlideArrows>
          <span className="slider-btn custom_prev_i1">
            <i className="fi-rs-arrow-small-left"></i>
          </span>
          <span className="slider-btn custom_next_i1">
            <i className="fi-rs-arrow-small-right"></i>
          </span>
        </SlideArrows>
      </FooterTitle>
      <div className="container">
        <Swiper
          navigation={{
            prevEl: ".custom_prev_i1",
            nextEl: ".custom_next_i1",
          }}
          className="custom-class overflow-hidden"
          slidesPerView={1}
          spaceBetween={10}
          breakpoints={{
            575: {
              slidesPerView: 1,
            },
            767: {
              slidesPerView: 2,
            },
            991: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          }}
        >
          <div className="">
            {banners?.main_btm_s &&
              banners.main_btm_s?.length > 0 &&
              banners.main_btm_s.map((el, i) => (
                <SwiperSlide key={i}>
                  <SNSboard href={el.link_url} target={"_blank"}>
                    <img src={el.P_FileUrl} alt={el.img_alt} />
                    <div>{el.img_alt}</div>
                  </SNSboard>
                </SwiperSlide>
              ))}
          </div>
        </Swiper>
      </div>
    </Instagram>
  );
};
export default Subscibe;
